<template>
	<div class="full-height pa-20">
		<h6>{{ program.name }}</h6>
        <div class="flex-row">
            <div
                class="pa-10 bg-white width-80"
            >
			<table class="table td-left">
				<col width="120px">
				<tbody>
                <tr>
                    <th>구분</th>
                    <td>
                        <div
                            class="pa-5-10 bg-identify width-60px text-center"
                        >{{ view_name }}</div>
                    </td>
                </tr>
				<tr>
					<th>제목</th>
					<td>
                        <input v-if="can_modify" v-model="item.title" class="input-box pa-10" placeholder="제목을 입력하세요" maxlength="50" :disable="is_disable" />
                        <p v-else>{{item.title}}</p>
                    </td>
				</tr>
				<tr>
					<th>내용</th>
					<td>
						<editor
							v-if="item.content && can_modify"
							height="350px"
							initialEditType="markdown"
							ref="content"
							class="text-left "
							:initialValue="item.content"
							:disable="is_disable"
						/>
                        <Viewer
                            v-if="item.content && !can_modify"
                            :initialValue="item.content"
                            class="pa-10 bg-gray-light box-conents-for-answer overflow-y-auto"
                            style="height: 550px"
                        />
					</td>
				</tr>
				<tr v-if="can_modify">
					<th>노출 여부</th>
					<td>
						<button
							class="pa-5-10 "
							:class="item.exposure ? 'bg-identify' : 'bg-identify-outline'"
							@click="item.exposure = true"
							:disable="is_disable"
						>노출</button>
						<button
							class="pa-5-10 mr-10"
							:class="!item.exposure ? 'bg-identify' : 'bg-identify-outline'"
							@click="item.exposure = false"
							:disable="is_disable"
						>숨김</button>
					</td>
				</tr>
				<tr v-if="can_modify">
					<th>상단 고정</th>
					<td>
						<button
							class="pa-5-10 "
							:class="item.fix ? 'bg-identify' : 'bg-identify-outline'"
							@click="item.fix = true"
							:disable="is_disable"
						>고정</button>
						<button
							class="pa-5-10 mr-10"
							:class="!item.fix ? 'bg-identify' : 'bg-identify-outline'"
							@click="item.fix = false"
							:disable="is_disable"
						>미고정</button>

						<label>
							상단 고정 선택 시 최근 등록 순으로 정렬됩니다.
						</label>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				class="mt-30 justify-center"
			>
				<button
                    v-if="can_modify"
					class="bg-identify pa-10-20 mr-10"
					@click="save"
				>저장</button>
				<button
					class="bg-ddd pa-10-20"
					@click="toList"
                >목록
                </button>
            </div>
            </div>
            <ul class="ml-10 pa-10 bg-white width-20" v-if="item.viewGroup === 'SELECT' && selectedIds.length > 0">
                <li class="box mb-5 pa-5-10" v-for="item in selectedIds" :key="item.idx">
                    <p class="mr-5">{{ item.name }}</p>
                </li>
            </ul>
        </div>
	</div>
</template>

<script>

import '@toast-ui/editor/dist/toastui-editor.css';
import {Editor, Viewer} from '@toast-ui/vue-editor';

export default {
	name: 'NoticeDetail'
	,components: {Viewer, Editor}
	,data: function(){
		return {
            user: [],
			program: {
				name: '공지사항 상세'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
				title: ''
				, content: ''
				, hour: ''
				, min: ''
				, distributor: ''
				, is_use: 1
				, viewGroup: 0
			}
            , viewGroup: [
                {name: '전체', code: 'ALL'},
                {name: '총판', code: 'BRANCH'},
                {name: '지사', code: 'DISTRIBUTOR'},
                {name: '대리점', code: 'AGENCY'},
                {name: '딜러', code: 'RESELLER'},
                {name: '가맹점', code: 'SHOP'},
                {name: '선택', code: 'SELECT'},
            ]
            , selectedIds: []
		}
	}
	,computed: {
		is_disable: function(){
			let is = false

				if(this.$route.name == 'NoticeBranch' && this.user.roleGroup != 'branch'){
					is = true
				}
			return is
		}
        , can_modify: function (){
            let is = false
            if( this.user.roleGroup == 'admin') {
                is = true
            }
            return is
        }
        , view_name: function (){
            let name = ''
            this.viewGroup.forEach((item) => {
                if(this.item.viewGroup === item.code) {
                    name = item.name
                }
            })
            return name
        }
	}
	, methods: {
		save: async function(){
			try{
				this.$layout.onLoading()

				let content = this.$refs.content.invoke("getMarkdown")

				this.item.content = content
				const result = await this.$Axios({
					method: 'put'
					,url: 'notice/' + this.item.idx
					,data: this.item
				})
				if(result.success){
					this.$layout.setNotify( { type: 'success', message: result.message})
				}else{
					this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,getData: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'notice/admin/' + this.$route.params.idx
				})
				if(result.success){
					this.item = result.data
                    this.selectedIds = result.data.selects
                    console.log(this.item)
				}else{
					if(result.message){
						this.$layout.setNotify( { type: 'error', message: result.message })
						this.$router.back()
					}else{
						this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
					}
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,close: function(){
			this.is_select = false
		}
		,clear: function(){
			this.is_select = false
		}
		,toList: function(){
			this.$router.back()
		}
	}
	, created() {
		if(!this.$route.params.idx){
			this.$layout.setNotify( { type: 'error', message: '잘못된 접근입니다'})
			this.$router.back()
			return
		}
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
		this.getData()
	}
}
</script>

<style>
.toastui-editor-defaultUI-toolbar button {
	margin: 7px 0px;
}
</style>